<template>
  <div>
    <h2 class="section-title font-weight-bold text-start" :class="['text-' + textColor]" data-aos="fade-right">
      {{ title }}
    </h2>
    <div class="app-divider mt-3" :class="['bg-' + color]" data-aos="fade-right" data-aos-delay="250"></div>
  </div>
</template>
<script>
export default {
  props: {
    title: { type: String, required: true },
    color: { type: String, required: true },
    textColor: { type: String, required: false, default: () => 'white' }
  }
};
</script>
