<template>
  <section id="challenge-share" class="text-white d-flex flex-column">
    <div class="commet-background flex-fill pt-4 pb-5 px-4 d-flex flex-column">
      <SectionTitleHeader :title="title" color="general-knowledge" class="pt-5 mb-5" />
      <main class="d-flex flex-column justify-content-center align-items-center mb-5" data-aos="zoom-in" data-aos-delay="1000">
        <img alt="Challenge Mode Image" :src="challengeModeIcon" width="242" height="172" />
        <p class="font-italic legend --big">{{ description }}</p>
      </main>
      <div class="d-flex justify-content-space-between">
        <button type="button" class="btn bg-green-neon flex-fill rounded-button" data-aos="fade-left" data-aos-offset="-100" data-aos-delay="500" @click="action">
          {{ CTA }}
        </button>
      </div>
    </div>
  </section>
</template>
<script>
import SectionTitleHeader from '@/components/SectionTitleHeader.vue';

export default {
  components: { SectionTitleHeader },
  methods: {
    getMessage() {
      return this.$store.state.challenge.nickname + ' has challenged you to play Question of the Day!';
    },
    getLink() {
      const link = 'https://qotd-dev.otherdigital.com/' + this.$root.$i18n.locale + '/challenge/' + this.$store.state.challenge.session_data._id + '/join';
      return link;
    },
    action() {
      navigator.share({
        title: this.$t('message.lets-play') + ' ' + this.$t('app.title'),
        text: this.getMessage(),
        url: this.getLink()
      });
      this.$router.push({ name: 'challenge-host-done', params: this.$route.params });
    }
  },
  computed: {
    challengeModeIcon() {
      return require('@/assets/images/swords.png');
    },
    description() {
      return 'Click the button below to share the challenge with you friend!';
    },
    title() {
      return 'Challenge created!';
    },
    CTA() {
      return 'Share Link';
    }
  },
  mounted() {
    if (!this.$store.state.challenge.session_data) {
      this.$router.push({ name: 'challenge-' + this.$route.meta.as, params: this.$route.params });
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/custom-vars/_colors.scss';

#challenge-share {
  background: $gradient-challenge-mode;
  box-shadow: -5px 0px 10px rgba(0, 0, 0, 0.4);
  min-height: 100%;
  main {
    flex-grow: 2;
  }
  button.--outlined {
    border-color: $green-neon;
  }
}
</style>
